.row-no-padding {
  margin-left: 0;
  margin-right: 0; }
  .row-no-padding [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important; }

.unit-list-row {
  max-height: 75vh;
  overflow-y: scroll; }

.margin-top-10 {
  margin-top: 10px; }

.ignition-status {
  vertical-align: middle;
  display: inline-block;
  height: 27px;
  width: 27px;
  border-radius: 50%;
  border: 2px solid #666;
  background-color: silver;
  margin: 0 3px; }
  .ignition-status.ignition-on {
    background-color: cornflowerblue; }
    .ignition-status.ignition-on.ignition-movement {
      background-color: limegreen; }

.speed-indicator {
  margin: 1px 0 0 0;
  display: inline-block;
  font-weight: bold;
  width: 28px;
  height: 28px;
  font-size: 11px;
  text-align: center;
  line-height: 25px;
  vertical-align: middle;
  background-color: white;
  border: 2px solid crimson;
  border-radius: 50%;
  color: #333;
  padding: 0; }

#unit-list .unit-selected {
  background-color: lavender; }

.header-icon {
  font-size: 1.5em; }

#vehicle-detail h4 {
  font-size: 24px;
  margin: 0; }

.map-report-button {
  margin: 5px 5px;
  width: 100%; }

.spinner {
  width: 20px;
  height: 20px; }
